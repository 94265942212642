<template>
    <div>
        <b-row>
            <b-col xs="12">
                <b-card>

                    <b-row>
                        <b-col sm="4" md="4">
                            <b-form-group
                                label="Seleccionar Año"
                                label-for="year"
                            >
                                <v-select
                                    v-model="filtersForm.year"
                                    label="name"
                                    :options="years"
									@input="onChangeDate($event)"
                                    :clearable="false"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col sm="4" md="4">
                            <b-form-group
                                label="Seleccionar Mes"
                                label-for="month"
                            >
                                <v-select
                                    v-model="filtersForm.month"
                                    label="name"
                                    :options="months"
									@input="onChangeDate($event)"
                                    :clearable="false"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col sm="4" md="4">
                            <b-form-group
                                label="Seleccionar Rubro"
                                label-for="business_area"
                            >
                                <v-select
                                    v-model="filtersForm.business_areas"
                                    label="name"
                                    multiple
                                    :options="business_areas"
                                />
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col sm="4" md="8">
                            <b-form-group
                                label="Seleccionar Proveedor"
                                label-for="provider"
                            >
                                <v-select
                                    v-model="filtersForm.providers"
                                    label="name"
                                    multiple
                                    :options="providers"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col sm="4" md="4">
                            <b-form-group
                                label="Seleccionar Tipo de Empresa"
                                label-for="business_type"
                            >
                                <v-select
                                    v-model="filtersForm.business_types"
                                    label="type"
                                    multiple
                                    :options="business_types"
                                />
                            </b-form-group>
                        </b-col>
                    </b-row>
                    
                    <b-row>
                        <b-col sm="12" class="my-auto"> 
                            <b-button
                                size="sm"
                                variant="primary"
                                :block="true"
                                :disabled="btnFilterDisabled"
                                @click="filterData"
                            >                                    
                                <feather-icon icon="FilterIcon" size="1x"/>   
                                FILTRAR
                            </b-button>
                            <hr>
                        </b-col>
                    </b-row>

                    <json-excel :data="tableExport()" :name="getExcelName()"></json-excel>
                    
                    <b-table
                        ref="table"
                        striped
                        hover
                        responsive
                        class="position-relative text-center table-analisis-pu"
                        :per-page="perPage"
                        :current-page="currentPage"
                        :items="items"
                        :fields="fields"
                        :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc"
                        :sort-direction="sortDirection"
                        :filter="filter"
                        :filter-included-fields="filterOn"
                        @filtered="onFiltered"
                        show-empty
                        empty-text="No se encontraron registros coincidentes"
                    >
    
                    </b-table>

                </b-card>
            </b-col>
        </b-row>
    </div>  
</template>

<script>

    import JsonExcel from "./download-excel";
    import moment from 'moment/src/moment';
    import vSelect from 'vue-select';
    import axios from 'axios';

    export default {
        components: {
            JsonExcel,
            vSelect
        },
        data() {
            return {
                btnFilterDisabled: true,

                perPage: 50000,
                pageOptions: [10, 15, 20, 25, 30],
                totalRows: 1,
                currentPage: 1,

                sortBy: '',
                sortDesc: false,
                sortDirection: 'asc',
                filter: null,   
                filterOn: [],
                fields: [{
                    key: 'enumeration',
                    label: 'ITEM',
                    sortable: false
                },
                {
                    key: 'proveedor',
                    label: 'PROVEEDOR',
                    sortable: false
                },
                {
                    key: 'rubro',
                    label: 'RUBRO',
                    sortable: false
                },
                {
                    key: 'tariff_type',
                    label: 'TIPO DE EMPRESA',
                    sortable: false
                },
                {
                    key: 'sueldo_ctta',
                    label: 'SUELDO CTTA',
                    sortable: false
                },
                {
                    key: 'sueldo_tarifario',
                    label: 'SUELDO TARIFARIO',
                    sortable: false
                },
                {
                    key: 'diferencia',
                    label: 'DIFERENCIA',
                    sortable: false
                },
                {
                    key: 'var_percent',
                    label: 'VAR. %',
                    sortable: false
                }],
                items: [],

                // ====================== 
                
                filtersForm: {
                    year: null,
                    month: null,
                    business_areas: [],
                    business_types: [],
                    providers: []
                },

                years: [],
                months: [],
                business_areas: [],
                business_types: [],
                providers: []
            }
        },
        async beforeMount() {
            await this.getBusinessAreaForAnalysisPU();
            await this.getProviders();

            const currentYear = parseInt(moment().format('YYYY'));
            const currentMonth = parseInt(moment().subtract(1, 'months').format('MM'));

            this.years = [];
            this.months = [];
            
            for (var i = 2023; i <= currentYear; i++)
            {
                this.years.push({ id: i, name: i });
            }

            this.months.push({ id: 1, name: 'ENERO' });
            this.months.push({ id: 2, name: 'FEBRERO' });
            this.months.push({ id: 3, name: 'MARZO' });
            this.months.push({ id: 4, name: 'ABRIL' });
            this.months.push({ id: 5, name: 'MAYO' });
            this.months.push({ id: 6, name: 'JUNIO' });
            this.months.push({ id: 7, name: 'JULIO' });
            this.months.push({ id: 8, name: 'AGOSTO' });
            this.months.push({ id: 9, name: 'SEPTIEMBRE' });
            this.months.push({ id: 10, name: 'OCTUBRE' });
            this.months.push({ id: 11, name: 'NOVIEMBRE' });
            this.months.push({ id: 12, name: 'DICIEMBRE' });

            this.filtersForm.year = this.years.find(year => year.id === currentYear);
            this.filtersForm.month = this.months.find(month => month.id === currentMonth);

			await this.getBusinessTypes(this.filtersForm);

            await this.getDataTable();
        },
        methods: {
            async getBusinessAreaForAnalysisPU(){
                const response = await axios.get(`${process.env.VUE_APP_HOST}/api/dashboard/business-area/select?pu=1`)
                this.business_areas = response.data;

                if (this.business_areas.length === 1)
                {
                    this.filtersForm.business_areas.push(this.business_areas[0])
                }
            },
			onChangeDate (e) {
				this.filtersForm.business_types = [];
				this.getBusinessTypes(this.filtersForm);
			},
            async getBusinessTypes({ year, month }){
				if (!year && !year.id || !month && !month.id) return;
				
				const businessAreaIds = this.filtersForm.business_areas.map(item => item.id);
                const response = await axios.get(`${process.env.VUE_APP_HOST}/api/dashboard/tariffs/select?year=${year.id}&month=${month.id}&business_area_id=${businessAreaIds}`)
                this.business_types = response.data;
            },
            async getProviders(){
                const response = await axios.get(`${process.env.VUE_APP_HOST}/api/dashboard/providers/select`)
                this.providers = response.data;
            },
            async getDataTable (){
                this.btnFilterDisabled = true;

                const year = this.filtersForm.year.id;
                const month = this.filtersForm.month.id;
                
                const businessAreas = this.filtersForm.business_areas ? this.filtersForm.business_areas.map(item => item.id) : [];
                const businessTypes = this.filtersForm.business_types ? this.filtersForm.business_types.map(item => item.id) : [];
                const providers = this.filtersForm.providers ? this.filtersForm.providers.map(item => item.id) : [];
                
                let response = await axios.get(`${process.env.VUE_APP_HOST}/api/dashboard/reporteria/table-analysis-pu?year=${year}&month=${month}&businessAreas=${businessAreas.join(',')}&businessTypes=${businessTypes.join(',')}&providers=${providers.join(',')}`);

                this.items = response.data;
                this.perPage = this.items.length;

                this.totalRows = response.data.length;

                this.currentPage = 1;
                this.sortBy = '';
                this.sortDesc = false;
                this.sortDirection = 'asc';
                this.filter = null;

                this.btnFilterDisabled = false;
            },
            async filterData (){
                await this.getDataTable();
                this.$refs['table'].refresh();
            },
            onFiltered(filteredItems) {
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            },
            getExcelName(){
                let fileName = 'analisis_pu.xls';

                if (this.filtersForm.month && this.filtersForm.year)
                {
                    fileName = `analisis_pu_${this.filtersForm.month.name.toLowerCase()}_${this.filtersForm.year.id}.xls`;
                }
                
                return fileName;
            },
            removeCurrencyFormat (item, currency = 'PEN') {
                if (currency === 'PEN') item = item.replace('S/ ', '');

                if (currency === 'USD') item = item.replace('$ ', '');

                item = item.replaceAll(',', '');

                return item
            },
            tableExport (){
                let array = [];

                for (let a = 0; a < this.items.length; a++)
                {
                    let objBody = {
                        'ITEM': (a + 1 === this.items.length) ? null : (a + 1),
                        'PROVEEDOR': this.items[a]['proveedor'],
                        'RUBRO': this.items[a]['rubro'],
                        'TIPO DE EMPRESA': this.items[a]['tariff_type'],
                        'SUELDO CTTA': this.removeCurrencyFormat(this.items[a]['sueldo_ctta']),
                        'SUELDO TARIFARIO': this.removeCurrencyFormat(this.items[a]['sueldo_tarifario']),
                        'DIFERENCIA': this.removeCurrencyFormat(this.items[a]['diferencia']),
                        'VAR': this.items[a]['var_percent']
                    };

                    array.push(objBody);
                }

                return array;
            }
        }
    }

</script>

<style scoped>

    input[type=text] {
        border: none;
        border-radius: 0px;
        background: transparent;
        border-bottom: 1px solid #d8d6de;
    }

</style>

<style>

.table-analisis-pu .b-table tbody tr td:nth-child(2) {
    text-align: left !important;
}

</style>